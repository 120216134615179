// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies();
@include mat.elevation-classes();
@include mat.app-background();

$teal-palette: (
  50: #3BD4AE,
  contrast: (50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    // ... continues to 900
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$CityOnsite-primary: mat.m2-define-palette(mat.$m2-teal-palette);
$CityOnsite-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);


// The warn palette is optional (defaults to red).
$CityOnsite-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$CityOnsite-theme: mat.m2-define-light-theme((color: (primary: $CityOnsite-primary,
        accent: $CityOnsite-accent,
        warn: $CityOnsite-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($CityOnsite-theme);

/* You can add global styles to this file, and also import other style files */

:root {
  --teal-dark: #008996;
  --teal-medium: #009383;
  --teal-light: #3BD4AE;
  --dark-blue: #0F1E41;
  --white: #ffffff;
  --grey: #c8c9cc;
  --city-blue: #002F87;
  --city-neutral: #7C7E82;
  --city-green: #80BC00;
  --city-neutral-light: #F6F6F6;
  --city-red: #FF3131;
  --city-yellow: #FBBF24;
  --city-blue-green: #069;
}

body {
  height: 100%;
  font-size: 1em;
  font-weight: 500;
  overflow: hidden;
  font-family: "Roboto", "General Sans", sans-serif !important;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  height: 2em;
}

p {
  color: var(--dark-blue);
  font-size: 0.9em;
}

.info-html-container {
  width: 100%;
  overflow-x: scroll;

  p {
    font-size: 1.0em;
  }
}

.info-html-container::-webkit-scrollbar {
  display: none;
}

h1 {
  font-weight: 600;
  color: var(--city-blue);
  font-size: 1.5em;
}

h5 {
  font-weight: 600;
  color: var(--city-blue);
  font-size: 1em;
}

.font-size-h1 {
  //28px
  font-size: 1.75em;
  font-weight: 600;
}

.font-size-h2 {
  //22px
  font-size: 1.37em;
  font-weight: 600;
}

.font-size-h3 {
  //20px
  font-size: 1.37em;
  font-weight: 600;
}

.font-size-display {
  //34px
  font-size: 2.12em;
  font-weight: 600;
}

.font-size-headline {
  //17px
  font-size: 1.06em;
  font-weight: 600;
}

.font-size-sub-headline {
  //15px
  font-size: 0.94em;
  font-weight: 600;
}

.font-size-body-text {
  //16px
  font-size: 1em;
  font-weight: 500;
}

.font-size-body-text-bold {
  //16px
  font-size: 1em;
  font-weight: 600;
}

.font-size-small-text {
  //14px
  font-size: 0.75em;
  font-weight: 500;
}

.font-size-small-text-bold {
  //14px
  font-size: 0.75em;
  font-weight: 600;
}

.font-size-caption1 {
  //12px
  font-size: 0.75em;
  font-weight: 500;
}

.font-size-caption2 {
  //11px
  font-size: 0.69em;
  font-weight: 500;
}

:root {
  .mdc-text-field--filled {
    .mdc-line-ripple::after {
      border-bottom-color: var(--city-blue) !important;
    }
  }

  .mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__shadow {
    background-color: var(--city-blue) !important;
  }

  .mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__track::after {
    background-color: var(--city-blue) !important;
  }

  .mat-internal-form-field {
    align-items: unset;
  }

  .mat-internal-form-field>label {
    align-content: center;
  }
 
  .mat-mdc-checkbox-touch-target{
    position: relative;
  }

  .mat-mdc-form-field-error {
    color: var(--city-red) !important;
  }

  .mat-mdc-dialog-container .mdc-dialog__surface { padding: 1em !important; }

  .mat-mdc-raised-button:not([disabled]) {
    background-color: var(--city-blue);
    color: var(--white);
  }

  .mat-drawer-content{
      box-sizing: unset;
  }

  .mdc-button .mdc-button__label {
    width: 100%;
  }

  .mat-mdc-option .mdc-list-item__primary-text{
    color : var(--city-blue) !important;
  }

  // .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
  //   color : var(--city-blue) !important;
  // }

  button {
    height: 4em;
  }

  .mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__leading {
    border-color: var(--city-blue) !important;
  }

  .mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__notch {
    border-color: var(--city-blue) !important;
  }

  .mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__trailing {
    border-color: var(--city-blue) !important;
  }
  
  .mat-mdc-input-element:disabled {
    color: var(--dark-blue);
  }
  
  mat-label {
    color: var(--city-blue);
    font-size:medium;
  }

  .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: var(--city-blue);
  }
  
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  // mat-checkbox {
  //   border: var(--teal-light);
  // }
  
  // /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  // .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background{
  //   background-color: var(--teal-light);
  // }
  
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  // .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  //   color: var(--city-blue)!important;
  // }
  
  .mat-icon-small{
    font-size:large;
  }
 
  .mat-toolbar-row,
  .mat-toolbar-single-row {
    height: 2em;
  }

  .mat-toolbar-override {
    background: var(--white);
    color: var(--white);
    height: 78px;
    display: flex;
    box-sizing: border-box;
    padding: 0px 0px 0px 0px !important;
    width: 100%;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    position: relative;
  }

  .main-content {
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 2em;
    margin-bottom: 4em;
    font-family: Roboto,
      "General Sans", sans-serif;
  }
  
  //app content global
  
  .main-content-status-bar {
    height: 1.375em;
    padding-top: env(safe-area-inset-top, 1.375em);
    background-color: white;
    position: fixed;
    width: -webkit-fill-available;
  }
  
  .main-content-status-bar-web {
    padding-top: 1.375em;
    position: fixed;
    width: -webkit-fill-available;
  }
  
  .main-content-status-bar-web-no-mat-toolbar {
    padding-top: 1.375em;
    position: fixed;
    width: -webkit-fill-available;
  }
  
  .main-content-status-bar-web-with-mat-toolbar {
    padding-top: 78px;
    width: -webkit-fill-available;
   
  }
  
  .mat-sidenav-web-no-mat-toolbar-height {
    height: calc(100vh) !important;
  }
  
  .mat-sidenav-web-with-mat-toolbar-height {
    height: calc(100vh - 78px) !important;
  }
  
  .img, svg {
    color : var(--city-blue);
  }

  .mat-icon.mat-primary {
    --mat-icon-color : var(--city-blue);
  }

  .mat-expansion-panel {
    box-shadow: none !important;
  }

  .mat-mdc-checkbox-ripple, .mdc-checkbox__ripple {
    display: none !important;
  }
  
  .mat-mdc-fab {
    background-color: var(--city-blue);
    min-height: auto;
    box-shadow: none;
  }

  .mat-mdc-fab:hover, .mat-mdc-fab:focus {
    box-shadow: none;
  }

  .mat-mdc-radio-button.mat-accent  {
    --mdc-radio-selected-icon-color: var(--city-blue);
    --mdc-radio-selected-focus-icon-color: var(--city-blue);
    --mdc-radio-selected-hover-icon-color: var(--city-blue);
    --mat-radio-checked-ripple-color: transparent;
    --mdc-radio-selected-pressed-icon-color: var(--city-blue);
  }

  .mat-mdc-radio-button .mdc-radio{
    padding: unset;
  }
  
  .color-city-red {
    color: var(--city-red);
  }

// .matInput {
//   border-color: (--teal-light);
//   color: var(--city-blue);
// }

// .mat-input-element:disabled {
//   color: var(--dark-blue);
// }

// mat-label {
//   color: var(--city-blue);
// }

// mat-checkbox {
//   border: var(--teal-light);
// }

// .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
// .mat-checkbox-checked.mat-accent .mat-checkbox-background {
//   background-color: var(--teal-light);
// }

// .mdc-text-field--focused .mdc-notched-outline > * {
//   border-color: var(--city-blue) !important;
// }

// .mat-icon-small {
//   font-size: large;
// }

.bg-teal-dark {
  color: var(--city-blue);
}

.color-teal-medium {
  color: var(--teal-medium);
}

.color-teal-dark {
  color: var(--teal-dark);
}

.color-dark-blue {
  color: var(--dark-blue);
}

.border-teal-light {
  border-color: var(--teal-light)
}

.color-teal-light {
  color: var(--teal-light)
}

.color-city-blue {
  color: var(--city-blue);
}

.bg-city-blue {
  background-color: var(--city-blue);
}

.color-city-neutral {
  color: var(--city-neutral)
}

.color-city-green {
  color: var(--city-green);
}

.color-city-red {
  color: var(--city-red);
}

.color-city-yellow {
  color: var(--city-yellow);
}

.bubble {
  background-color: var(--city-blue);
  padding: 8px;
  border-radius: 18px;
  color: var(--white);
  font-size: 0.6em;
}

.bg-white {
  background-color: var(--white);
}

.bg-grey {
  background-color: var(--grey);
}

.bg-primary-blue {
  color: var(--city-blue);
}

.display-bold {
  font-weight: 600;
}

}

button {
  width: 100%;
  height: 4em;
}

.divider {
  padding-top: 1em;
}

.divider3 {
  padding-top: 3em;
}

.main-content {
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 2em;
  margin: 0;
}

//app content global

.main-content-status-bar {
  height: 1.375em;
  padding-top: env(safe-area-inset-top, 1.375em);
  background-color: white;
  position: fixed;
  width: -webkit-fill-available;
}

.main-content-status-bar-web {
  padding-top: 1.375em;
  position: fixed;
  width: -webkit-fill-available;
}

.main-content-status-bar-web-no-mat-toolbar {
  padding-top: 1.375em;
  position: fixed;
  width: -webkit-fill-available;
}

.main-content-status-bar-web-with-mat-toolbar {
  padding-top: 78px;
  width: -webkit-fill-available;
}

.mat-sidenav-web-no-mat-toolbar-height {
  height: calc(100vh) !important;
}

.mat-sidenav-web-with-mat-toolbar-height {
  height: calc(100vh - 78px) !important;
}

.secondary-text-color {
  color: #3BD4AE;
}

.button-teal-light {
  background-color: #E6F6F2;
}

.mat-slide-toggle.mat-primary.mat-checked {

  .mat-slide-toggle-thumb {
    background-color: var(--city-blue)
  }

  .mat-slide-toggle-bar {
    background-color: #4e7bce
  }
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: var(--city-blue);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: var(--city-blue);
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  background-color: transparent !important;
}

.mat-mdc-header-row, .mat-mdc-row {
  border-bottom: 0.1em solid rgba(0, 0, 0, 0.5);
}

// .mat-mdc-table {
//   background: transparent;
//   color: black;
// }

// .mat-mdc-header-cell {
//   color: black;
// }

// .mat-mdc-row {
//   border-top: 0.1em solid rgba(0, 0, 0, 0.5);
// }

// .mat-select-panel {
//   position: absolute;
//   top: 32px;
//   left: 4px;
//   min-width: calc(100% + 22px) !important;
//   border-radius: 0px !important;
//   border-top-style: none;
// }

// // Dropdown box 

// .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
//   color: var(--city-blue) !important;
// }

// .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
//   color: var(--city-blue) !important;
// }

// // paginator 

// .mat-mdc-paginator {
//   background: transparent;
//   color: black;
// }

// .mat-mdc-select-arrow,
// .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
//   color: black !important;
// }

// .mat-mdc-select-value-text,
// .mat-mdc-form-field.mat-focused.mat-primary .mmat-mdc-select-value-text {
//   color: black;
// }

// .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
// .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
// .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
// .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
// .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
// .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
//   border-color: white;
//   border-bottom-color: black;
// }

// .mdc-menu-surface.mat-mdc-select-panel {
//   background: white;
// }

// .mdc-list-item__primary-text,
// .mdc-list-item--selected .mdc-list-item__primary-text {
//   color: black;
// }

// .mat-mdc-option,
// .mat-mdc-option:hover:not(.mdc-list-item--disabled),
// .mat-mdc-option:focus:not(.mdc-list-item--disabled),
// .mat-mdc-option.mat-mdc-option-active,
// .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
//   background: white;
//   font-size: 12px;

//   &:hover {
//     background: whitesmoke;
//   }
// }

// .mat-mdc-paginator-icon {
//   fill: black !important;
// }

// .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
//   fill: gainsboro !important;
// }

// QR WEB 

qr-scanner {
  width: 100% !important;
  height: 100% !important;
}

qr-scanner .cameraButtonDiv {
  width: 94% !important;
}

qr-scanner .btn-camera {
  background-color: var(--city-blue);
  height: 4em;
  width: 100% !important;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
  transform: translate3d(0, 0, 0);

  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: white;
}

qr-scanner label[for=qr_upload] {
  padding-top: 0.6em;
}

qr-scanner video {
  margin-top: 4.5em;
  width: 100%;
}

// QR WEB 

//hide scrollbar

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

mat-sidenav-content::-webkit-scrollbar {
  display: none;
}

mat-sidenav-content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

//hide scrollbar

.mat-icon.mat-primary {
  color: var(--city-blue);
  cursor: pointer;
}